import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

import '../css/blog.css';

import Layout from "../layouts/"
import Head from "../components/head"

import Pagination from "../components/pagination"

import DefaultHero from "../components/default-hero"
import SectionHero from "../components/section-hero";

import Sidebar from "../components/sidebar"

import placeholder from "../images/blog1.jpg";

import YoastSeoParts from '../fragments/seo';
import YoastSeoTermParts from '../fragments/seo-term';

class CategoryTemplate extends Component {
  render() {
    const category = this.props.data.wpCategory;
    const { seo } = category
    const posts = this.props.data.allWpPost.nodes;
    const mostRecent = this.props.data.mostRecent.nodes;

    const { currentPage, numPages } = this.props.pageContext

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />
        
        {/* <DefaultHero title={category.name} /> */}
        <SectionHero section="resource" title={category.name}/>

        <div className="container blogMain">
          <div className="grid smallGrid">
            <div className="col-sm-8">

                { posts &&
                  posts.map(post => {
                    const { id, title, wpURI, excerpt, featuredImage, categories } = post;

                    let categoryList = '';
                    if(categories) {
                      categories.nodes.map((cat, index) => {
                        categoryList += index == 0 ? cat.name : ', '+cat.name;
                      })
                    }

                    let backgroundStyles = {'backgroundImage': 'url('+placeholder+')'}
                    if(featuredImage) {
                      backgroundStyles = {'backgroundImage' : 'url('+featuredImage.node.localFile.publicURL+')'}
                    }

                    return (
                      <div key={id} className="col-md-6">
                        <div className="blogBox">
                          <Link to={wpURI}>
                            { backgroundStyles &&
                            <div className="blogBoxImage" style={backgroundStyles}></div>
                            }
                          </Link>
                          <div className="blogBoxBody">
                            <Link className="blogBoxTitle" to={wpURI}>{title}</Link>
                            <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
                          </div>
                          <div className="blogBoxFooter">
                            <div className="grid">
                              <div className="col-sm-7">
                                <p className="blogBoxCat">{categoryList}</p>
                              </div>
                              <div className="col-sm-5">
                                <Link className="blogBoxLink" to={wpURI}>Read More</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

                <Pagination uri={category.wpURI} currentPage={currentPage} numPages={numPages} />
            </div>
            <div className="col-sm-4">
              <Sidebar posts={mostRecent} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CategoryTemplate

export const pageQuery = graphql`
  query ($id: String!, $skip: Int!, $limit: Int!, $include: [String]) {
    wpCategory(
      id: {eq: $id}
    ) {
      name
      wpURI
      seo {
        ...seoTermParts
      }
    }

    allWpPost (
      sort: {fields: date, order: DESC}
      skip: $skip
      limit: $limit
      filter: {categories: {nodes: {elemMatch: {id: {in: $include}}}}}
    ) {
      nodes {
        id
        title
        wpURI
        excerpt
        date(formatString: "MMMM DD, YYYY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }

    mostRecent: allWpPost (sort: {fields: date, order: DESC}, limit: 5) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`